// 1. Colors
$fg: #000000;
$fg-secondary: #000000;
$bg: #ffffff;
$bg-secondary: #BDBDBD;
$bd: #757575;

// 2. Breakpoints
$breakpoints: (xxs: 320px, // mobile sm
  xs: 480px, // mobile
  sm: 768px, // tablet
  md: 1024px, // tablet lg / laptop sm
  lg: 1280px, // laptop
  xl: 1440px, // display
  xxl: 1920px // display lg
);

// 3. Spacing
$sp: 1rem; // 16
$sp-xs: $sp * 0.5; // 8
$sp-sm: $sp * 0.75; // 12
$sp-md: $sp * 1.5; // 24
$sp-lg: $sp * 2; // 32
$sp-xl: $sp * 4; // 64

$sp-header: $sp * 8;
$sp-col: 20vw;

// 4. Fonts
$ff: 'Bau',
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
Roboto,
Oxygen,
Ubuntu,
Cantarell,
'Open Sans',
'Helvetica Neue',
sans-serif;

$fs: 1rem; // 16
$fs-xs: $fs * 0.5; // 8
$fs-sm: $fs * 0.75; // 12
$fs-md: $fs * 1.5; // 24
$fs-lg: $fs * 2; // 32
$fs-xl: $fs * 6; // 64
$fs-xxl: $fs * 6; // 96

$lh: 1.25; // 24

// 5. Text styles
.ts {
  font-size: $fs;
  line-height: $lh;
}

.ts-xs {
  font-size: $fs-xs;
  line-height: $lh;
}

.ts-sm {
  font-size: $fs-sm;
  line-height: $lh;
}

.ts-md {
  font-size: $fs-md;
  line-height: $lh;
}

.ts-lg {
  font-size: $fs-lg;
  line-height: $lh;
}

.ts-xl {
  font-size: $fs-xl;
  line-height: $lh;
  @include media('>=md') {
    font-size: $fs-xxl;
  }
}

.ts-bold {
  font-weight: 600;
}

.ts-italic {
  font-style: italic;
}

.ts-link {
  color: $fg;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ts-body-link {
  color: $fg-secondary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// 6. Markdown
.body {
  max-width: 35ch;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr {
    &:not(:first-child) {
      margin-top: $sp-md;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre {
    &:not(:last-child) {
      margin-bottom: $sp;
    }
  }

  h1 {
    @extend .ts-xl;
  }

  h2 {
    @extend .ts-lg;
  }

  h3 {
    @extend .ts-md;
  }

  // p {
  // line-height: $lh-xs;
  // max-width: 75ch;
  // }

  ul,
  ol {
    padding-left: $sp-md;
  }

  a {
    @extend .ts-body-link;
  }

  b,
  strong {
    @extend .ts-bold;
  }

  i,
  em {
    @extend .ts-italic;
  }

  hr {
    border: none;
    height: 1px;
    background-color: $bd;
  }
}