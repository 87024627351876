.info {
	padding: $sp-header*1.5 $sp-md $sp-md $sp-md;
	display: grid;
	grid-template-columns: 1fr;
	column-gap: $sp-lg;
	@include media('>=md') {
    padding: $sp-header $sp-md $sp-md $sp-md;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	&__col {
		max-width: 35ch;
		display: flex;
		flex-direction: column;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: $sp-xl;
      @include media('>=md') {
        margin-bottom: 0;
      }
    }

    @include media('>=md') {
      height: calc(100vh - #{$sp-header} - #{$sp-md});
    }
	}

	&__media {
    padding-top: $sp-md;
	}

	&__body {
    @extend .body;
    @include media('>=md') {
        padding-bottom: $sp-md;
    }
	}
}
