@import 'utils/_reset';
@import 'utils/_fonts';
@import 'utils/_include-media';
@import 'utils/_variables';
@import 'utils/_medias';
@import 'utils/_global';
@import 'utils/_flickity';
@import 'utils/_fullpage';

@import 'components/_header';
@import 'components/_hero';
@import 'components/_main';
@import 'components/_section';
@import 'components/_grid';
@import 'components/_fullpage';
@import 'components/_index';
@import 'components/_block';
@import 'components/_footer';
@import 'components/_icon';
@import 'components/_info';
@import 'components/_detail';

html {
  font-size: 13px;

  @include media('>=sm') {
    font-size: 14px;
  }

  @include media('>=md') {
    font-size: 15px;
  }

  @include media('>=xl') {
    font-size: 16px;
  }

  @include media('>=xl') {
    font-size: 18px;
  }
}

body {
  @extend .ts;
  font-family: $ff;
  font-weight: 500;
  color: $fg;
  background: $bg;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}