.fullpage {
  .block {
    width: 100%;
    height: 100vh;
    padding: $sp-xl $sp-col $sp-xl $sp-md;

    @include media('>=md') {
      padding: $sp-header $sp-col $sp-header $sp-xl;
    }

    &__link {
      height: 100%;
    }

    &__media {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .section:nth-of-type(odd) .block {
    padding: $sp-xl $sp-md $sp-xl $sp-col;

    @include media('>=md') {
      padding: $sp-header $sp-xl $sp-header $sp-col;
    }
  }
}