.hero {
  height: calc(100vh - #{($sp*2) + ($fs*$lh)});
  position: relative;

  &__media {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}