.header {
  @extend .ts-md;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.25s opacity;

  &.hide {
    opacity: 0;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: $sp-md;
    font-weight: 600;

    &__bau {
      letter-spacing: -0.05rem;
    }

    &__club {
      letter-spacing: 0.05rem;
    }
  }

  .nav {
    position: absolute;
    top: 0;
    right: 0;
    padding: $sp-md;

    &__item {
      text-align: right;

    }

    &__link {
      display: block;
      margin-bottom: $sp-xs;

      &--active {
        margin-bottom: $sp-md;
      }
    }
  }
}
