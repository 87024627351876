.detail {
  @include media('>=md') {
    height: 100vh;
  }

	&__left {
    width: 100%;
    padding: $sp-header $sp-col 0 $sp-md;

    @include media('>=md') {
      min-height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
	}

	&__title {
    @extend .ts-xl;
		text-transform: uppercase;
    margin-bottom: $sp-xl;
    // @include media('>=md') {
    //   margin-bottom: $sp-header;
    // }
	}

	&__intro {
    @extend .body;
    margin-bottom: $sp-xl;
	}

	&__body {
		@extend .body;
    margin-bottom: $sp-xl;
    @include media('>=md') {
      margin-bottom: $sp-md;
    }
    ul{
      padding-left: 0;
      margin-top: $sp-md;

    }
	}

	&__nav {
    margin-top: $sp-md;
		padding-bottom: $sp-md;
    // display: none;
    //
    // @include media('>=md') {
    //   display: block;
    // }
	}

	&__right {
    width: 100%;
    padding: $sp-md $sp-md $sp-header $sp-md;

    @include media('>=md') {
      position: fixed;
      top: 0;
      right: 0;
      width: 50%;
      height: 100vh;
      padding: $sp-header $sp-md;
    }
  }

  .flickity,
  .flickity__list,
  .flickity-viewport,
  .flickity-slider,
  .flickity__item {
    @include media('>=md') {
      height: 100% !important;
    }
  }

	.flickity {
    position: relative;

		&__item {
			width: 100%;
			img {
        // cursor: zoom-in;
        height: calc(100vh - #{$sp-header*2});
				width: 100%;
				object-fit: contain;
        object-position: 0 0;
        @include media('>=md') {
          max-height: unset;
          height: 100%;
        }
			}
		}

    &__nav {
      display: flex;
      position: absolute;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $sp-md;
      right: 0;
      bottom: -50px;

      @include media('>=md') {
        position: fixed;
        bottom: 0;
        right: 12px;
      }
    }

    &__btn--prev, &__btn--next {
      @extend .ts-md;
      position: relative;
      top:-6px;
    }

    &__btn--prev {
      padding-right: $sp-sm;
    }

    &__btn--next {
      padding-left: $sp-sm;
      padding-right: $sp-sm;
    }

    &__fullscreen-exit {
      @extend .ts-md;
      cursor: pointer;
      display: none;
    }

    &__counter {
      display: flex;
      // @extend .ts-md;
    }

    &__separator {
      margin-left: $sp-md;
      margin-right: $sp-md;
    }
  }

  .flickity--fullscreen {
    z-index: 10000;
    position: fixed;
    background: #fffe;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $sp-md $sp-md $sp-xl $sp-md;

    .flickity__nav {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0 $sp-md;
      right: 0;
    }

    .flickity__fullscreen-exit {
      display: block;
    }
    .flickity__item img {
      cursor: zoom-out;
      object-position: 50% 50%;
      height: 100%;
    }
    .flickity__list,
    .flickity-viewport,
    .flickity-slider,
    .flickity__item {
        height: 100% !important;
    }
  }
}
