/* line 1, resources/assets/front/css/utils/_reset.scss */
* {
  box-sizing: border-box; }

/* line 5, resources/assets/front/css/utils/_reset.scss */
::after,
::before {
  box-sizing: inherit; }

/* line 10, resources/assets/front/css/utils/_reset.scss */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

/* line 16, resources/assets/front/css/utils/_reset.scss */
body {
  margin: 0; }

/* line 20, resources/assets/front/css/utils/_reset.scss */
main {
  display: block; }

/* line 24, resources/assets/front/css/utils/_reset.scss */
address,
blockquote,
dl,
figure,
form,
iframe,
p,
pre,
table {
  margin: 0; }

/* line 36, resources/assets/front/css/utils/_reset.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0; }

/* line 48, resources/assets/front/css/utils/_reset.scss */
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* line 55, resources/assets/front/css/utils/_reset.scss */
dt {
  font-weight: bold; }

/* line 59, resources/assets/front/css/utils/_reset.scss */
dd {
  margin-left: 0; }

/* line 63, resources/assets/front/css/utils/_reset.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid;
  margin: 0;
  clear: both;
  color: inherit; }

/* line 74, resources/assets/front/css/utils/_reset.scss */
pre {
  font-family: monospace, monospace;
  font-size: inherit; }

/* line 79, resources/assets/front/css/utils/_reset.scss */
address {
  font-style: inherit; }

/* line 83, resources/assets/front/css/utils/_reset.scss */
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }

/* line 89, resources/assets/front/css/utils/_reset.scss */
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

/* line 95, resources/assets/front/css/utils/_reset.scss */
b,
strong {
  font-weight: bolder; }

/* line 100, resources/assets/front/css/utils/_reset.scss */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit; }

/* line 107, resources/assets/front/css/utils/_reset.scss */
small {
  font-size: 80%; }

/* line 111, resources/assets/front/css/utils/_reset.scss */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 119, resources/assets/front/css/utils/_reset.scss */
sub {
  bottom: -0.25em; }

/* line 123, resources/assets/front/css/utils/_reset.scss */
sup {
  top: -0.5em; }

/* line 127, resources/assets/front/css/utils/_reset.scss */
img {
  border-style: none;
  vertical-align: bottom; }

/* line 132, resources/assets/front/css/utils/_reset.scss */
embed,
iframe,
object {
  border: 0;
  vertical-align: bottom; }

/* line 139, resources/assets/front/css/utils/_reset.scss */
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit; }

/* line 158, resources/assets/front/css/utils/_reset.scss */
[type='checkbox'] {
  -webkit-appearance: checkbox;
  appearance: checkbox; }

/* line 163, resources/assets/front/css/utils/_reset.scss */
[type='radio'] {
  -webkit-appearance: radio;
  appearance: radio; }

/* line 168, resources/assets/front/css/utils/_reset.scss */
button,
input {
  overflow: visible; }

/* line 173, resources/assets/front/css/utils/_reset.scss */
button,
select {
  text-transform: none; }

/* line 178, resources/assets/front/css/utils/_reset.scss */
[type='button'],
[type='reset'],
[type='submit'],
button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none; }

/* line 187, resources/assets/front/css/utils/_reset.scss */
[type='button'][disabled],
[type='reset'][disabled],
[type='submit'][disabled],
button[disabled] {
  cursor: default; }

/* line 194, resources/assets/front/css/utils/_reset.scss */
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/* line 202, resources/assets/front/css/utils/_reset.scss */
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* line 209, resources/assets/front/css/utils/_reset.scss */
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0; }

/* line 216, resources/assets/front/css/utils/_reset.scss */
legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

/* line 224, resources/assets/front/css/utils/_reset.scss */
progress {
  vertical-align: baseline; }

/* line 228, resources/assets/front/css/utils/_reset.scss */
textarea {
  overflow: auto; }

/* line 232, resources/assets/front/css/utils/_reset.scss */
[type='checkbox'],
[type='radio'] {
  padding: 0; }

/* line 237, resources/assets/front/css/utils/_reset.scss */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

/* line 242, resources/assets/front/css/utils/_reset.scss */
[type='search'] {
  outline-offset: -2px; }

/* line 246, resources/assets/front/css/utils/_reset.scss */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 250, resources/assets/front/css/utils/_reset.scss */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* line 255, resources/assets/front/css/utils/_reset.scss */
label[for] {
  cursor: pointer; }

/* line 259, resources/assets/front/css/utils/_reset.scss */
details {
  display: block; }

/* line 263, resources/assets/front/css/utils/_reset.scss */
summary {
  display: list-item; }

/* line 267, resources/assets/front/css/utils/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 272, resources/assets/front/css/utils/_reset.scss */
caption {
  text-align: left; }

/* line 276, resources/assets/front/css/utils/_reset.scss */
td,
th {
  vertical-align: top; }

/* line 281, resources/assets/front/css/utils/_reset.scss */
th {
  text-align: left;
  font-weight: bold; }

/* line 286, resources/assets/front/css/utils/_reset.scss */
template {
  display: none; }

/* line 290, resources/assets/front/css/utils/_reset.scss */
[hidden] {
  display: none; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-regular-italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-medium-italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Bau';
  src: url("fonts/bau-bold-italic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

/* line 53, resources/assets/front/css/utils/_variables.scss */
.ts, body {
  font-size: 1rem;
  line-height: 1.25; }

/* line 58, resources/assets/front/css/utils/_variables.scss */
.ts-xs {
  font-size: 0.5rem;
  line-height: 1.25; }

/* line 63, resources/assets/front/css/utils/_variables.scss */
.ts-sm {
  font-size: 0.75rem;
  line-height: 1.25; }

/* line 68, resources/assets/front/css/utils/_variables.scss */
.ts-md, .body h3, .section__header__content h3, .info__body h3, .detail__intro h3, .detail__body h3, .header, .index--fixed .index__menu, .footer, .detail .flickity__btn--prev, .detail .flickity__btn--next, .detail .flickity__fullscreen-exit {
  font-size: 1.5rem;
  line-height: 1.25; }

/* line 73, resources/assets/front/css/utils/_variables.scss */
.ts-lg, .body h2, .section__header__content h2, .info__body h2, .detail__intro h2, .detail__body h2, .section__header__title {
  font-size: 2rem;
  line-height: 1.25; }

/* line 78, resources/assets/front/css/utils/_variables.scss */
.ts-xl, .body h1, .section__header__content h1, .info__body h1, .detail__intro h1, .detail__body h1, .detail__title {
  font-size: 6rem;
  line-height: 1.25; }
  @media (min-width: 1024px) {
    /* line 78, resources/assets/front/css/utils/_variables.scss */
    .ts-xl, .body h1, .section__header__content h1, .info__body h1, .detail__intro h1, .detail__body h1, .detail__title {
      font-size: 6rem; } }

/* line 86, resources/assets/front/css/utils/_variables.scss */
.ts-bold, .body b, .section__header__content b, .info__body b, .detail__intro b, .detail__body b,
.body strong,
.section__header__content strong,
.info__body strong,
.detail__intro strong,
.detail__body strong {
  font-weight: 600; }

/* line 90, resources/assets/front/css/utils/_variables.scss */
.ts-italic, .body i, .section__header__content i, .info__body i, .detail__intro i, .detail__body i,
.body em,
.section__header__content em,
.info__body em,
.detail__intro em,
.detail__body em {
  font-style: italic; }

/* line 94, resources/assets/front/css/utils/_variables.scss */
.ts-link {
  color: #000000;
  text-decoration: none; }
  /* line 98, resources/assets/front/css/utils/_variables.scss */
  .ts-link:hover {
    text-decoration: underline; }

/* line 103, resources/assets/front/css/utils/_variables.scss */
.ts-body-link, .body a, .section__header__content a, .info__body a, .detail__intro a, .detail__body a {
  color: #000000;
  text-decoration: none; }
  /* line 107, resources/assets/front/css/utils/_variables.scss */
  .ts-body-link:hover, .body a:hover, .section__header__content a:hover, .info__body a:hover, .detail__intro a:hover, .detail__body a:hover {
    text-decoration: underline; }

/* line 113, resources/assets/front/css/utils/_variables.scss */
.body, .section__header__content, .info__body, .detail__intro, .detail__body {
  max-width: 35ch; }
  /* line 123, resources/assets/front/css/utils/_variables.scss */
  .body h1:not(:first-child), .section__header__content h1:not(:first-child), .info__body h1:not(:first-child), .detail__intro h1:not(:first-child), .detail__body h1:not(:first-child),
  .body h2:not(:first-child),
  .section__header__content h2:not(:first-child),
  .info__body h2:not(:first-child),
  .detail__intro h2:not(:first-child),
  .detail__body h2:not(:first-child),
  .body h3:not(:first-child),
  .section__header__content h3:not(:first-child),
  .info__body h3:not(:first-child),
  .detail__intro h3:not(:first-child),
  .detail__body h3:not(:first-child),
  .body h4:not(:first-child),
  .section__header__content h4:not(:first-child),
  .info__body h4:not(:first-child),
  .detail__intro h4:not(:first-child),
  .detail__body h4:not(:first-child),
  .body h5:not(:first-child),
  .section__header__content h5:not(:first-child),
  .info__body h5:not(:first-child),
  .detail__intro h5:not(:first-child),
  .detail__body h5:not(:first-child),
  .body h6:not(:first-child),
  .section__header__content h6:not(:first-child),
  .info__body h6:not(:first-child),
  .detail__intro h6:not(:first-child),
  .detail__body h6:not(:first-child),
  .body hr:not(:first-child),
  .section__header__content hr:not(:first-child),
  .info__body hr:not(:first-child),
  .detail__intro hr:not(:first-child),
  .detail__body hr:not(:first-child) {
    margin-top: 1.5rem; }
  /* line 137, resources/assets/front/css/utils/_variables.scss */
  .body h1:not(:last-child), .section__header__content h1:not(:last-child), .info__body h1:not(:last-child), .detail__intro h1:not(:last-child), .detail__body h1:not(:last-child),
  .body h2:not(:last-child),
  .section__header__content h2:not(:last-child),
  .info__body h2:not(:last-child),
  .detail__intro h2:not(:last-child),
  .detail__body h2:not(:last-child),
  .body h3:not(:last-child),
  .section__header__content h3:not(:last-child),
  .info__body h3:not(:last-child),
  .detail__intro h3:not(:last-child),
  .detail__body h3:not(:last-child),
  .body h4:not(:last-child),
  .section__header__content h4:not(:last-child),
  .info__body h4:not(:last-child),
  .detail__intro h4:not(:last-child),
  .detail__body h4:not(:last-child),
  .body h5:not(:last-child),
  .section__header__content h5:not(:last-child),
  .info__body h5:not(:last-child),
  .detail__intro h5:not(:last-child),
  .detail__body h5:not(:last-child),
  .body h6:not(:last-child),
  .section__header__content h6:not(:last-child),
  .info__body h6:not(:last-child),
  .detail__intro h6:not(:last-child),
  .detail__body h6:not(:last-child),
  .body hr:not(:last-child),
  .section__header__content hr:not(:last-child),
  .info__body hr:not(:last-child),
  .detail__intro hr:not(:last-child),
  .detail__body hr:not(:last-child),
  .body p:not(:last-child),
  .section__header__content p:not(:last-child),
  .info__body p:not(:last-child),
  .detail__intro p:not(:last-child),
  .detail__body p:not(:last-child),
  .body pre:not(:last-child),
  .section__header__content pre:not(:last-child),
  .info__body pre:not(:last-child),
  .detail__intro pre:not(:last-child),
  .detail__body pre:not(:last-child) {
    margin-bottom: 1rem; }
  /* line 159, resources/assets/front/css/utils/_variables.scss */
  .body ul, .section__header__content ul, .info__body ul, .detail__intro ul, .detail__body ul,
  .body ol,
  .section__header__content ol,
  .info__body ol,
  .detail__intro ol,
  .detail__body ol {
    padding-left: 1.5rem; }
  /* line 178, resources/assets/front/css/utils/_variables.scss */
  .body hr, .section__header__content hr, .info__body hr, .detail__intro hr, .detail__body hr {
    border: none;
    height: 1px;
    background-color: #757575; }

/*------------------------------------*\
  medias
\*------------------------------------*/
/* line 6, resources/assets/front/css/utils/_medias.scss */
img,
video,
object {
  max-width: 100%;
  height: auto; }

/* line 13, resources/assets/front/css/utils/_medias.scss */
img {
  display: block; }

/* line 17, resources/assets/front/css/utils/_medias.scss */
img.image-lazy {
  width: 100%; }
  /* line 20, resources/assets/front/css/utils/_medias.scss */
  img.image-lazy:not(.lazyloaded) {
    opacity: 0;
    transition: opacity 1s ease-in-out; }
  /* line 25, resources/assets/front/css/utils/_medias.scss */
  img.image-lazy.lazyloaded {
    opacity: 1;
    transition: opacity 1s ease-in-out; }

/* line 31, resources/assets/front/css/utils/_medias.scss */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

/* line 39, resources/assets/front/css/utils/_medias.scss */
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
/* line 5, resources/assets/front/css/utils/_flickity.scss */
.flickity-enabled {
  position: relative; }

/* line 9, resources/assets/front/css/utils/_flickity.scss */
.flickity-enabled:focus {
  outline: none; }

/* line 13, resources/assets/front/css/utils/_flickity.scss */
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

/* line 19, resources/assets/front/css/utils/_flickity.scss */
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
/* line 27, resources/assets/front/css/utils/_flickity.scss */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 35, resources/assets/front/css/utils/_flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

/* line 41, resources/assets/front/css/utils/_flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
/* line 48, resources/assets/front/css/utils/_flickity.scss */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

/* line 55, resources/assets/front/css/utils/_flickity.scss */
.flickity-button:hover {
  background: white;
  cursor: pointer; }

/* line 60, resources/assets/front/css/utils/_flickity.scss */
.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

/* line 65, resources/assets/front/css/utils/_flickity.scss */
.flickity-button:active {
  opacity: 0.6; }

/* line 69, resources/assets/front/css/utils/_flickity.scss */
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

/* line 76, resources/assets/front/css/utils/_flickity.scss */
.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
/* line 82, resources/assets/front/css/utils/_flickity.scss */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

/* line 91, resources/assets/front/css/utils/_flickity.scss */
.flickity-prev-next-button.previous {
  left: 10px; }

/* line 95, resources/assets/front/css/utils/_flickity.scss */
.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
/* line 100, resources/assets/front/css/utils/_flickity.scss */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

/* line 105, resources/assets/front/css/utils/_flickity.scss */
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

/* line 110, resources/assets/front/css/utils/_flickity.scss */
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
/* line 120, resources/assets/front/css/utils/_flickity.scss */
.flickity-page-dots {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

/* line 129, resources/assets/front/css/utils/_flickity.scss */
.flickity-rtl .flickity-page-dots {
  direction: rtl; }

/* line 133, resources/assets/front/css/utils/_flickity.scss */
.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/*!
 * fullPage 3.0.8
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-enabled body, html.fp-enabled {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slide {
  float: left; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-section.fp-table, .fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slidesContainer {
  float: left;
  position: relative; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-scrollable {
  overflow: hidden;
  position: relative; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-scroller {
  overflow: hidden; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.iScrollIndicator {
  border: 0 !important; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0); }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav.fp-right {
  right: 17px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav.fp-left {
  left: 17px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slidesNav.fp-bottom {
  bottom: 17px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slidesNav.fp-top {
  top: 17px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li, .fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-slidesNav ul li {
  display: inline-block; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li a, .fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li a span, .fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial,helvetica,sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  width: auto;
  opacity: 1; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-auto-height.fp-section {
  height: auto !important; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section {
  height: auto !important; }

/* line 10, resources/assets/front/css/utils/_fullpage.scss */
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/*# sourceMappingURL=fullpage.min.css.map */
/* line 1, resources/assets/front/css/components/_header.scss */
.header {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.25s opacity; }
  /* line 10, resources/assets/front/css/components/_header.scss */
  .header.hide {
    opacity: 0; }
  /* line 14, resources/assets/front/css/components/_header.scss */
  .header .logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.5rem;
    font-weight: 600; }
    /* line 21, resources/assets/front/css/components/_header.scss */
    .header .logo__bau {
      letter-spacing: -0.05rem; }
    /* line 25, resources/assets/front/css/components/_header.scss */
    .header .logo__club {
      letter-spacing: 0.05rem; }
  /* line 30, resources/assets/front/css/components/_header.scss */
  .header .nav {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem; }
    /* line 36, resources/assets/front/css/components/_header.scss */
    .header .nav__item {
      text-align: right; }
    /* line 41, resources/assets/front/css/components/_header.scss */
    .header .nav__link {
      display: block;
      margin-bottom: 0.5rem; }
      /* line 45, resources/assets/front/css/components/_header.scss */
      .header .nav__link--active {
        margin-bottom: 1.5rem; }

/* line 1, resources/assets/front/css/components/_hero.scss */
.hero {
  height: calc(100vh - 3.25rem);
  position: relative; }
  /* line 5, resources/assets/front/css/components/_hero.scss */
  .hero__media {
    position: absolute;
    width: 100%;
    height: 100%; }
    /* line 10, resources/assets/front/css/components/_hero.scss */
    .hero__media img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

/* line 2, resources/assets/front/css/components/_section.scss */
.section__header {
  margin-bottom: 2rem; }
  /* line 8, resources/assets/front/css/components/_section.scss */
  .section__header__title:not(:last-child) {
    margin-bottom: 1.5rem; }

/* line 1, resources/assets/front/css/components/_grid.scss */
.grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  row-gap: 2rem; }
  @media (min-width: 768px) {
    /* line 1, resources/assets/front/css/components/_grid.scss */
    .grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1024px) {
    /* line 1, resources/assets/front/css/components/_grid.scss */
    .grid {
      grid-template-columns: repeat(4, 1fr); } }

/* line 2, resources/assets/front/css/components/_fullpage.scss */
.fullpage .block {
  width: 100%;
  height: 100vh;
  padding: 4rem 20vw 4rem 1.5rem; }
  @media (min-width: 1024px) {
    /* line 2, resources/assets/front/css/components/_fullpage.scss */
    .fullpage .block {
      padding: 8rem 20vw 8rem 4rem; } }
  /* line 11, resources/assets/front/css/components/_fullpage.scss */
  .fullpage .block__link {
    height: 100%; }
  /* line 15, resources/assets/front/css/components/_fullpage.scss */
  .fullpage .block__media {
    height: 100%; }
    /* line 18, resources/assets/front/css/components/_fullpage.scss */
    .fullpage .block__media img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

/* line 26, resources/assets/front/css/components/_fullpage.scss */
.fullpage .section:nth-of-type(odd) .block {
  padding: 4rem 1.5rem 4rem 20vw; }
  @media (min-width: 1024px) {
    /* line 26, resources/assets/front/css/components/_fullpage.scss */
    .fullpage .section:nth-of-type(odd) .block {
      padding: 8rem 4rem 8rem 20vw; } }

/* line 1, resources/assets/front/css/components/_index.scss */
.wrapper--home .index--fixed {
  position: fixed; }

/* line 5, resources/assets/front/css/components/_index.scss */
.index--fixed {
  min-height: 100%;
  min-height: -webkit-fill-available; }
  /* line 10, resources/assets/front/css/components/_index.scss */
  .index--fixed .index__menu {
    display: inline-block;
    position: relative;
    padding: 8rem 4rem 1.5rem 1.5rem;
    z-index: 2;
    line-height: 1.4em; }
    @media (min-width: 1024px) {
      /* line 10, resources/assets/front/css/components/_index.scss */
      .index--fixed .index__menu {
        width: 20vw; } }
    /* line 20, resources/assets/front/css/components/_index.scss */
    .index--fixed .index__menu .nav {
      display: inline-block; }
  /* line 25, resources/assets/front/css/components/_index.scss */
  .index--fixed .index__content {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1; }
    /* line 36, resources/assets/front/css/components/_index.scss */
    .index--fixed .index__content .block {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      mix-blend-mode: multiply;
      padding: 1.5rem; }
      @media (min-width: 1024px) {
        /* line 36, resources/assets/front/css/components/_index.scss */
        .index--fixed .index__content .block {
          padding: 4rem 1.5rem 4rem 1.5rem; } }
      @media screen and (min-width: 768px) and (min-height: 800px) {
        /* line 36, resources/assets/front/css/components/_index.scss */
        .index--fixed .index__content .block {
          padding: 8rem 4rem 8rem 0; } }
      /* line 52, resources/assets/front/css/components/_index.scss */
      .index--fixed .index__content .block__link {
        height: 100%; }
      /* line 56, resources/assets/front/css/components/_index.scss */
      .index--fixed .index__content .block__media {
        height: 100%;
        display: flex;
        align-items: center; }
        /* line 60, resources/assets/front/css/components/_index.scss */
        .index--fixed .index__content .block__media img {
          display: block;
          margin: 0 auto;
          width: 80%;
          height: 80%;
          object-fit: contain; }
      /* line 68, resources/assets/front/css/components/_index.scss */
      .index--fixed .index__content .block__media .alternate:hover {
        cursor: pointer; }
    /* line 73, resources/assets/front/css/components/_index.scss */
    .index--fixed .index__content .block:not(:first-child) {
      display: none; }
  /* line 78, resources/assets/front/css/components/_index.scss */
  .index--fixed .index .nav__link {
    display: block;
    margin-bottom: 0.5rem; }

/* line 1, resources/assets/front/css/components/_footer.scss */
.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  text-align: right;
  transition: 0.25s opacity; }
  /* line 11, resources/assets/front/css/components/_footer.scss */
  .footer.hide {
    opacity: 0; }
  /* line 15, resources/assets/front/css/components/_footer.scss */
  .footer .nav__link {
    font-size: 2rem; }

/* line 1, resources/assets/front/css/components/_icon.scss */
.icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round; }

/* line 1, resources/assets/front/css/components/_info.scss */
.info {
  padding: 12rem 1.5rem 1.5rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem; }
  @media (min-width: 1024px) {
    /* line 1, resources/assets/front/css/components/_info.scss */
    .info {
      padding: 8rem 1.5rem 1.5rem 1.5rem;
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  /* line 11, resources/assets/front/css/components/_info.scss */
  .info__col {
    max-width: 35ch;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    /* line 17, resources/assets/front/css/components/_info.scss */
    .info__col:not(:last-child) {
      margin-bottom: 4rem; }
      @media (min-width: 1024px) {
        /* line 17, resources/assets/front/css/components/_info.scss */
        .info__col:not(:last-child) {
          margin-bottom: 0; } }
    @media (min-width: 1024px) {
      /* line 11, resources/assets/front/css/components/_info.scss */
      .info__col {
        height: calc(100vh - 8rem - 1.5rem); } }
  /* line 29, resources/assets/front/css/components/_info.scss */
  .info__media {
    padding-top: 1.5rem; }
  @media (min-width: 1024px) {
    /* line 33, resources/assets/front/css/components/_info.scss */
    .info__body {
      padding-bottom: 1.5rem; } }

@media (min-width: 1024px) {
  /* line 1, resources/assets/front/css/components/_detail.scss */
  .detail {
    height: 100vh; } }

/* line 6, resources/assets/front/css/components/_detail.scss */
.detail__left {
  width: 100%;
  padding: 8rem 20vw 0 1.5rem; }
  @media (min-width: 1024px) {
    /* line 6, resources/assets/front/css/components/_detail.scss */
    .detail__left {
      min-height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }

/* line 19, resources/assets/front/css/components/_detail.scss */
.detail__title {
  text-transform: uppercase;
  margin-bottom: 4rem; }

/* line 28, resources/assets/front/css/components/_detail.scss */
.detail__intro {
  margin-bottom: 4rem; }

/* line 33, resources/assets/front/css/components/_detail.scss */
.detail__body {
  margin-bottom: 4rem; }
  @media (min-width: 1024px) {
    /* line 33, resources/assets/front/css/components/_detail.scss */
    .detail__body {
      margin-bottom: 1.5rem; } }
  /* line 39, resources/assets/front/css/components/_detail.scss */
  .detail__body ul {
    padding-left: 0;
    margin-top: 1.5rem; }

/* line 46, resources/assets/front/css/components/_detail.scss */
.detail__nav {
  margin-top: 1.5rem;
  padding-bottom: 1.5rem; }

/* line 56, resources/assets/front/css/components/_detail.scss */
.detail__right {
  width: 100%;
  padding: 1.5rem 1.5rem 8rem 1.5rem; }
  @media (min-width: 1024px) {
    /* line 56, resources/assets/front/css/components/_detail.scss */
    .detail__right {
      position: fixed;
      top: 0;
      right: 0;
      width: 50%;
      height: 100vh;
      padding: 8rem 1.5rem; } }

@media (min-width: 1024px) {
  /* line 70, resources/assets/front/css/components/_detail.scss */
  .detail .flickity,
  .detail .flickity__list,
  .detail .flickity-viewport,
  .detail .flickity-slider,
  .detail .flickity__item {
    height: 100% !important; } }

/* line 80, resources/assets/front/css/components/_detail.scss */
.detail .flickity {
  position: relative; }
  /* line 83, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__item {
    width: 100%; }
    /* line 85, resources/assets/front/css/components/_detail.scss */
    .detail .flickity__item img {
      height: calc(100vh - 16rem);
      width: 100%;
      object-fit: contain;
      object-position: 0 0; }
      @media (min-width: 1024px) {
        /* line 85, resources/assets/front/css/components/_detail.scss */
        .detail .flickity__item img {
          max-height: unset;
          height: 100%; } }
  /* line 98, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__nav {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    right: 0;
    bottom: -50px; }
    @media (min-width: 1024px) {
      /* line 98, resources/assets/front/css/components/_detail.scss */
      .detail .flickity__nav {
        position: fixed;
        bottom: 0;
        right: 12px; } }
  /* line 114, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__btn--prev, .detail .flickity__btn--next {
    position: relative;
    top: -6px; }
  /* line 120, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__btn--prev {
    padding-right: 0.75rem; }
  /* line 124, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__btn--next {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  /* line 129, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__fullscreen-exit {
    cursor: pointer;
    display: none; }
  /* line 135, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__counter {
    display: flex; }
  /* line 140, resources/assets/front/css/components/_detail.scss */
  .detail .flickity__separator {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }

/* line 146, resources/assets/front/css/components/_detail.scss */
.detail .flickity--fullscreen {
  z-index: 10000;
  position: fixed;
  background: #fffe;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem 1.5rem 4rem 1.5rem; }
  /* line 156, resources/assets/front/css/components/_detail.scss */
  .detail .flickity--fullscreen .flickity__nav {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 1.5rem;
    right: 0; }
  /* line 164, resources/assets/front/css/components/_detail.scss */
  .detail .flickity--fullscreen .flickity__fullscreen-exit {
    display: block; }
  /* line 167, resources/assets/front/css/components/_detail.scss */
  .detail .flickity--fullscreen .flickity__item img {
    cursor: zoom-out;
    object-position: 50% 50%;
    height: 100%; }
  /* line 172, resources/assets/front/css/components/_detail.scss */
  .detail .flickity--fullscreen .flickity__list,
  .detail .flickity--fullscreen .flickity-viewport,
  .detail .flickity--fullscreen .flickity-slider,
  .detail .flickity--fullscreen .flickity__item {
    height: 100% !important; }

/* line 23, resources/assets/front/css/screen.scss */
html {
  font-size: 13px; }
  @media (min-width: 768px) {
    /* line 23, resources/assets/front/css/screen.scss */
    html {
      font-size: 14px; } }
  @media (min-width: 1024px) {
    /* line 23, resources/assets/front/css/screen.scss */
    html {
      font-size: 15px; } }
  @media (min-width: 1440px) {
    /* line 23, resources/assets/front/css/screen.scss */
    html {
      font-size: 16px; } }
  @media (min-width: 1440px) {
    /* line 23, resources/assets/front/css/screen.scss */
    html {
      font-size: 18px; } }

/* line 43, resources/assets/front/css/screen.scss */
body {
  font-family: "Bau", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #000000;
  background: #ffffff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
