.section {
  &__header {
    margin-bottom: $sp-lg;

    &__title {
      @extend .ts-lg;

      &:not(:last-child) {
        margin-bottom: $sp-md;
      }
    }

    &__content {
      @extend .body;
    }
  }
}