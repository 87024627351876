@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-regular-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-medium-italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Bau';
  src: url('fonts/bau-bold-italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}