.grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: $sp-md;
  row-gap: $sp-lg;

  @include media('>=sm') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>=md') {
    grid-template-columns: repeat(4, 1fr);
  }
}