/*------------------------------------*\
  medias
\*------------------------------------*/


img,
video,
object {
  max-width: 100%;
  height: auto;
}

img {
  display: block;
}

img.image-lazy {
  width: 100%;

  &:not(.lazyloaded) {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  &.lazyloaded {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}