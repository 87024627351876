.wrapper--home .index--fixed{
  position: fixed;
}

.index--fixed {
  min-height: 100%;
  min-height: -webkit-fill-available;

  .index {
    &__menu {
      display: inline-block;
      position: relative;
      @extend .ts-md;
      padding: $sp-header $sp-xl $sp-md $sp-md;
      z-index: 2;
      @include media('>=md') {
        width: $sp-col;
      }
      line-height: 1.4em;
      .nav{
        display: inline-block;
      }
    }

    &__content {
      // @include media('>=md') {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        // display: none;
        z-index: 1;
      // }

      .block {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        mix-blend-mode: multiply;
        padding: $sp-md;

        @include media('>=md') {
          padding: $sp-xl $sp-md $sp-xl $sp-md;
        }

        @media screen and (min-width: 768px) and ( min-height: 800px ){
          padding: $sp-header $sp-xl $sp-header 0;
        }

        &__link {
          height: 100%;
        }

        &__media {
          height: 100%;
          display: flex;
          align-items: center;
          img {
            display: block;
            margin: 0 auto;
            width: 80%;
            height: 80%;
            object-fit: contain;
          }
        }
        &__media .alternate:hover {
          cursor: pointer;
        }

      }
      .block:not(:first-child) {
        display: none;
      }
    }

    .nav__link {
      display: block;
      margin-bottom: $sp-xs;
    }
  }
}

// .index--scroll {
//   height: 100vh;
//   display: flex;
//   .index {
//
//     &__menu {
//       @extend .ts-md;
//       width: 10ch;
//       height: 100%;
//       overflow-y: auto;
//       padding: $sp-header $sp-xl $sp-header $sp-md;
//     }
//
//     &__content {
//       flex: 1;
//       height: 100%;
//       overflow-y: auto;
//       padding: $sp-header $sp-xl $sp-header $sp-md;
//
//       .block {
//         height: 75%;
//         width: 100%;
//         margin-bottom: $sp-header;
//         padding: 0 10vw;
//
//         &__link {
//           height: 100%;
//         }
//
//         &__media {
//           height: 100%;
//
//           img {
//             width: 100%;
//             height: 100%;
//             object-fit: contain;
//           }
//         }
//       }
//     }
//
//     .nav__link {
//       display: block;
//       margin-bottom: $sp-xs;
//     }
//   }
// }
