.footer {
  @extend .ts-md;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  padding: $sp-md;
  text-align: right;
  transition: 0.25s opacity;

  &.hide {
    opacity: 0;
  }

  .nav__link{
    font-size: 2rem;
  }
}
